(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("sbJsExtends"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("mobxReact"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-search-block", ["React", "mobx", "sbJsExtends", "sbInternalMsgBus", "sbRespBlockLib", "mobxReact"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-search-block"] = factory(require("React"), require("mobx"), require("sbJsExtends"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("mobxReact"));
	else
		root["sb-responsive-search-block"] = factory(root["React"], root["mobx"], root["sbJsExtends"], root["sbInternalMsgBus"], root["sbRespBlockLib"], root["mobxReact"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__9__) {
return 